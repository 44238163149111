<template>
	<div>
		<a-descriptions bordered :title="$t('table.CustomerManagement.IntentionDetail.personalDetail')" size="small">
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.firstName')">
				{{ detail.firstName }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.middleName')">
				{{ detail.middleName }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.lastName')">
				{{ detail.lastName }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.phoneNumber')">
				{{ detail.phoneNumber }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.gender')">
				{{ $t(`table.CustomerManagement.IntentionDetail.gender_map.${detail.gender}`) }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.fromdata.idNumber')">
				{{ detail.idNumber }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.fromdata.idPic')" :span="3">
				<viewer :images="idNumberFileList" class="img-box" style="width: auto">
					<img v-for="(src, idx) in idNumberFileList" :src="src" :key="idx" class="img_pic" />
				</viewer>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.CustomerManagement.IntentionDetail.carDetail')" size="small">
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.vehicleMake')">
				{{ detail.vehicleMake }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.vehicleModel')">
				{{ detail.vehicleModel }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.vehicleYesr')">
				{{ detail.vehicleYesr }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.fromdata.licensePlateNumber')">
				{{ detail.licensePlateNumber }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.vehicleValue')" :span="2">
				<a-statistic :precision="2" :value="detail.vehicleValue == null ? '' : detail.vehicleValue" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.fromdata.vehiclePic')" :span="3">
				<viewer :images="carViewFileList" class="img-box" style="width: auto">
					<img v-for="(src, idx) in carViewFileList" :src="src" :key="idx" class="img_pic" />
				</viewer>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.CustomerManagement.IntentionDetail.wishDetail')" size="small">
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.expectLoanAmount')">
				<a-statistic :precision="2" :value="detail.expectLoanAmount === null ? '' : detail.expectLoanAmount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.expectLoanTerm')">
				{{ detail.expectLoanTerm }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.loanUsage')">
				{{ detail.loanUsage }}
			</a-descriptions-item>
			<!-- <a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.hasOtherLoan')">
        {{ $t( `table.CustomerManagement.IntentionDetail.hasOtherLoan_map.${detail.hasOtherLoan}` ) }}
      </a-descriptions-item> -->
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.CustomerManagement.IntentionDetail.incomeDetail')" size="small">
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.monthIncome')">
				<a-statistic :precision="2" :value="detail.monthIncome == null ? '' : detail.monthIncome" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.monthExpense')">
				<a-statistic :precision="2" :value="detail.monthExpense == null ? '' : detail.monthExpense" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.monthLeft')">
				<a-statistic :precision="2" :value="detail.monthIncome - detail.monthExpense" />
			</a-descriptions-item>
		</a-descriptions>
		<!-- <a-descriptions bordered size="small">
      <template>
        <span slot="title">
          {{ $t("table.CustomerManagement.IntentionDetail.wishAmountDetail") }}
          <a-button
            type="primary"
            @click="refreshDetail()"
            v-t.preserve="'operate.refresh'"
          ></a-button>
        </span>
      </template>
      <a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.expectMaxLoanTotalAmount')">
        {{ detail.expectMaxLoanTotalAmount }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.expectMaxLoanTermAmount')">
        {{ detail.expectMaxLoanTermAmount }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.realWishDetail')">
        {{ detail.realWishDetail }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.expectLoanAmount')">
        {{ detail.confirmLoanAmount }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.expectLoanTerm')">
        {{ detail.confirmLoanTerm }}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.loanInterstRate')">
        {{ user.loanInterstRate }}%
      </a-descriptions-item>
      <a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.amountDue')">
        {{ Number( (detail.expectLoanAmount / detail.expectLoanTerm) * (1 + user.loanInterstRate * 0.01) ).toFixed(2) }}
      </a-descriptions-item>
    </a-descriptions> -->
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
	name: 'InfoDetail',
	props: ['detail', 'idNumberFileList', 'carViewFileList'],
	data() {
		return {
			loading: false,
			permissionValue: 'menus.customerManagementMenu.clue'
		}
	},
	computed: {
		...mapGetters(['user', 'enumerate', 'setting', 'permission'])
	},
	methods: {
		resetRoleModal() {},
		moment: moment,
		refreshDetail() {
			this.$emit('refreshDetail')
		}
	}
}
</script>

<style scoped lang="less">
.title button {
	float: right;
	margin-right: 10px;
}
</style>
