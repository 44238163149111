<template>
	<a-spin :spinning="pageLoading" class="spinning">
		<a-modal
			v-drag-modal
			v-model="invalidModal.show"
			:title="$t('page.invalid')"
			:destroyOnClose="true"
			width="800px"
			:confirm-loading="invalidModal.loading"
			@ok="handleInvalidModalOk"
		>
			<div class="invalidModal">
				<a-form-model
					:ref="invalidModal.form"
					:rules="invalidModal.rules"
					:model="invalidModal.data"
					:labelCol="invalidModal.labelCol"
					:wrapperCol="invalidModal.wrapperCol"
				>
					<!-- <a-form-model-item :label="$t('table.CustomerManagement.archiveReason')" prop="reason">
						<a-radio-group v-model="invalidModal.data.reason">
							<a-radio :value="item.id" v-for="item in reason_list" :key="item.id">
								{{ item.codeValue }}
							</a-radio>
						</a-radio-group>
					</a-form-model-item> -->
					<a-form-model-item :label="$t('table.CustomerManagement.invalidRemark')" prop="remark">
						<a-textarea :rows="4" v-model="invalidModal.data.remark" :maxLength="200" />
					</a-form-model-item>
				</a-form-model>
			</div>
		</a-modal>
		<div class="box" v-show="!pageLoading">
			<div class="btn_audit">
				<a-button
					type="primary"
					@click="switchStatus()"
					v-t.preserve="detail.status === '0' ? 'page.invalid' : 'page.valid'"
					style="margin-left: 10px"
				></a-button>
				<a-popconfirm :title="$t('operate.sureSetApplyCustomer')" ok-text="Yes" cancel-text="No" @confirm="setOperate()" v-if="detail.status === '0'">
					<a-button type="primary" v-t.preserve="'page.setApplyCustomer'" style="margin-left: 10px"></a-button>
				</a-popconfirm>
				<a-button v-if="detail.status === '3'" type="primary" v-t.preserve="'page.applyCustomer'" style="margin-left: 10px"></a-button>
			</div>
			<a-descriptions bordered :title="$t('page.userInfo')" size="small">
				<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.id')">
					{{ detail.firstName + ' ' + detail.middleName + ' ' + detail.lastName }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.createTime')">
					{{ detail.createTime }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.createType')">
					{{ $t(`table.CustomerManagement.IntentionDetail.createTypeMap.${detail.createType}`) }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.creator')">
					{{ detail.createUserDisplayName }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.CustomerManagement.Intention.status')">
					{{ $t(`table.CustomerManagement.Intention.status_map.${detail.status}`) }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.CustomerManagement.IntentionDetail.followuper')">
					{{ detail.username }}
				</a-descriptions-item>
			</a-descriptions>
			<a-tabs defaultActiveKey="0">
				<a-tab-pane :tab="$t('table.CustomerManagement.IntentionDetail.info-detail')" key="0">
					<!-- 资料详情 -->
					<info-detail
						v-bind:detail="detail"
						:idNumberFileList="idNumberFileList"
						:carViewFileList="carViewFileList"
						@refreshDetail="refreshDetail"
					></info-detail>
				</a-tab-pane>
				<a-tab-pane :tab="$t('table.CustomerManagement.IntentionDetail.followup-record')" key="1">
					<!-- 跟进记录 -->
					<followup-record
						v-bind:archiveRecords="archiveRecords"
						v-bind:feedbackRecords="feedbackRecords"
						v-bind:reason_obj="reason_obj"
					></followup-record>
				</a-tab-pane>
			</a-tabs>
		</div>
	</a-spin>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import FollowupRecord from './intention-detail-tab/followup-record'
import InfoDetail from './intention-detail-tab/info-detail'
import {
	apiInvalidPreApplication,
	apiPreApplication,
	apiPreApplicationFeedback,
	apiPreApplicationArchive,
	apiValidPreApplication,
	apiCastToApplyCustomer
} from '@/api/web'
import { apiGetCodeByName } from '@/api/code'
export default {
	components: { InfoDetail, FollowupRecord },
	data() {
		return {
			pageLoading: false,
			detail: {},
			idNumberFileList: [],
			carViewFileList: [],
			feedbackRecords: [],
			archiveRecords: [],
			queryObj: {},
			intentionId: null,
			loading: false,
			invalidModal: {
				form: 'invalidModalForm',
				labelCol: { span: 4 },
				wrapperCol: { span: 12 },
				loading: false,
				show: false,
				data: {
					reason: null,
					remark: null
				},
				rules: {
					remark: [
						{
							required: true,
							whitespace: true
						}
					]
				}
			},
			permissionValue: 'menus.customerManagementMenu.clue',
			reason_list: [],
			reason_obj: {}
		}
	},
	computed: {
		...mapGetters(['user', 'enumerate', 'setting', 'permission'])
	},

	beforeRouteUpdate(to, from, next) {
		this.queryObj = to.query
		this.intentionId = to.query.id
		this.table()
		next()
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.queryObj = to.query
		})
	},
	async mounted() {
		this.GetCodeName()
		this.queryObj = this.$route.query
		this.intentionId = this.$route.query.id
		this.table('init')
	},
	methods: {
		// 获取归档枚举
		async GetCodeName() {
			let data = await apiGetCodeByName({ name: 'ARCHIVE_REASON' })
			this.reason_list = data.values
			this.reason_obj = {}
			data.values.map((item) => {
				this.reason_obj[item.id] = item.codeValue
			})
		},
		moment: moment,
		resetInvalidModal() {
			this.invalidModal.data = {
				reason: null,
				remark: ''
			}
		},
		async showInvalidModal(data) {
			this.resetInvalidModal()
			this.invalidModal.data.id = this.intentionId
			this.invalidModal.show = true
		},
		async handleInvalidModalOk() {
			await this.$refs.invalidModalForm.validate()
			if (!this.invalidModal.data.remark) {
				return false
			}
			let params = {
				// deletedReasonType: this.invalidModal.data.reason,
				context: this.invalidModal.data.remark,
				preApplicationId: this.invalidModal.data.id
			}
			try {
				this.invalidModal.loading = true
				let res = await apiInvalidPreApplication(params)
				this.table()
				this.invalidModal.show = false
			} catch (e) {
				this.$error({
					content: e.message
				})
			}
			this.invalidModal.loading = false
		},
		async valid() {
			this.$confirm({
				content: this.$t('page.confirm'),
				onOk: async () => {
					let params = {
						id: this.intentionId
					}
					try {
						let res = await apiValidPreApplication(params)
						this.refreshDetail()
					} catch (e) {
						this.$error({
							content: e.message
						})
					}
				}
			})
		},
		switchStatus() {
			if (this.detail.status === '0') {
				this.showInvalidModal()
			} else {
				this.valid()
			}
		},
		setOperate() {
			let params = {
				id: this.intentionId
			}
			apiCastToApplyCustomer(params).then(() => {
				this.table()
			})
		},
		async refreshDetail() {
			this.pageLoading = true
			try {
				await this.table()
			} catch (e) {}
			this.pageLoading = false
		},
		table(order) {
			this.pageLoading = true
			apiPreApplication({ id: this.intentionId }).then((res) => {
				apiPreApplicationFeedback({
					id: this.intentionId
				}).then((res) => {
					this.feedbackRecords = res
				})
				apiPreApplicationArchive({
					id: this.intentionId
				}).then((res) => {
					this.archiveRecords = res
				})
				this.detail = res
				this.detail.idNumberFileList.backPhoto.forEach((element) => {
					this.idNumberFileList.push(this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, element.filePath))
				})
				this.detail.idNumberFileList.frontPhoto.forEach((element) => {
					this.idNumberFileList.push(this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, element.filePath))
				})
				this.detail.idNumberFileList.holdingPhoto.forEach((element) => {
					this.idNumberFileList.push(this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, element.filePath))
				})
				this.detail.carViewFileList.vehicleChassisFileList.forEach((element) => {
					this.carViewFileList.push(this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, element.filePath))
				})
				this.detail.carViewFileList.vehicleDetailsFileList.forEach((element) => {
					this.carViewFileList.push(this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, element.filePath))
				})
				this.detail.carViewFileList.vehicleEngineFileList.forEach((element) => {
					this.carViewFileList.push(this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, element.filePath))
				})
				this.detail.carViewFileList.vehicleExteriorFileList.forEach((element) => {
					this.carViewFileList.push(this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, element.filePath))
				})
				this.detail.carViewFileList.vehicleInteriorFileList.forEach((element) => {
					this.carViewFileList.push(this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, element.filePath))
				})
				this.pageLoading = false
			})
		}
	}
}
</script>

<style scoped lang="less">
.title button {
	float: right;
	margin-right: 10px;
}
</style>
