import { _axios } from '@/plugins/axios'

export const apiPreApplicationList = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/web/preApplication/list`,
    method: method,
    data: dataJson
  })
}
export const apiPreApplication = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/web/preApplication`,
    method: method,
    data: dataJson
  })
}
export const apiPreApplicationFeedback = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/web/preApplication/feedback`,
    method: method,
    data: dataJson
  })
}
export const apiPreApplicationArchive = (dataJson, method = 'get') => {
  return _axios({
    url: `/car/web/preApplication/archive`,
    method: method,
    data: dataJson
  })
}
export const apiValidPreApplication = (dataJson, method = 'post') => {
  return _axios({
    url: `/car/web/preApplication/unArchivePreApplication`,
    method: method,
    data: dataJson
  })
}
export const apiInvalidPreApplication = (dataJson, method = 'post') => {
  return _axios({
    url: `/car/web/preApplication/archivePreApplication`,
    method: method,
    data: dataJson
  })
}

export const apiAssignPreSalesMan = (dataJson, method = 'post') => {
  return _axios({
    url: `/car/web/preApplication/setApplicationSalesman`,
    method: method,
    data: dataJson
  })
}

export const apiCastToApplyCustomer = (dataJson, method = 'post') => {
  return _axios({
    url: `/car/web/preApplication/castToApplyCustomer`,
    method: method,
    data: dataJson
  })
}
