<template>
  <div>
    <a-descriptions class="table-descriptions" :title="$t('table.CustomerManagement.IntentionDetail.feedbackRecords')" size="small">
      <a-descriptions-item :span="3">
        <a-table
          style="font-size:12px;"
          :columns="feedbackColumns"
          size="small"
          :rowKey="(record, index) => index"
          :dataSource="feedbackRecords"
          :pagination="false"
          bordered
        >
        </a-table>
      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions class="table-descriptions" :title="$t('table.CustomerManagement.IntentionDetail.archiveRecords')" size="small">
      <a-descriptions-item :span="3">
        <a-table
          style="font-size:12px;"
          :columns="archiveColumns"
          size="small"
          :rowKey="(record, index) => index"
          :dataSource="archiveRecords"
          :pagination="false"
          bordered
          :scroll="{
            x: true
            // y: 300,
          }"
        >
        </a-table>
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "FollowupRecord",
  data() {
    return {
      feedbackColumns: [
        {
          // 反馈人ID
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.feedbackColumns.userName"
          ),
          dataIndex: "userName",
          align: "center"
        },
        {
          // 反馈人姓名
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.feedbackColumns.displayName"
          ),
          dataIndex: "displayName",
          align: "center"
        },
        {
          // 车辆是否估价
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.feedbackColumns.isVehicle"
          ),
          dataIndex: "hasAmount",
          align: "center",
          customRender: (v, o) => {
            o.hasAmount = o.hasAmount ? o.hasAmount : "0";
            return this.$t(
              `table.CustomerManagement.fromdata.AmountObj.options.${o.hasAmount}`
            );
          }
        },
        {
          // 是否已提供最近6个月交易流水
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.feedbackColumns.providedSix"
          ),
          dataIndex: "hasReport",
          align: "center",
          customRender: (v, o) => {
            o.hasReport = o.hasReport ? o.hasReport : "0";
            return this.$t(
              `table.CustomerManagement.fromdata.ReportObj.options.${o.hasReport}`
            );
          }
        },
        {
          // 跟进内容
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.feedbackColumns.context"
          ),
          dataIndex: "context",
          align: "center"
        },
        {
          // 时间
          title: this.$t("page.date"),
          dataIndex: "createTime",
          align: "center",
          customRender: v =>
            v ? this.moment(v).format("YYYY-MM-DD HH:mm") : "-"
        }
      ],
      archiveColumns: [
        {
          // 操作人ID
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.archiveColumns.userName"
          ),
          dataIndex: "userName",
          align: "center"
        },
        {
          // 反馈人姓名
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.archiveColumns.displayName"
          ),
          dataIndex: "displayName",
          align: "center"
        },
        {
          // 操作
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.archiveColumns.status"
          ),
          dataIndex: "status",
          align: "center",
          customRender: v =>
            v
              ? this.$t(
                  `table.CustomerManagement.IntentionDetail.archiveColumns.status_map.${v}`
                )
              : "-"
        },
        {
          // 归档理由
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.archiveColumns.reason"
          ),
          dataIndex: "deletedReasonType",
          align: "center",
          customRender: (v, o) => {
            return v ? this.reason_obj[v] : "-";
          }
        },
        {
          // 备注
          title: this.$t(
            "table.CustomerManagement.IntentionDetail.archiveColumns.context"
          ),
          dataIndex: "context",
          align: "center"
        },
        {
          // 时间
          title: this.$t("page.date"),
          dataIndex: "createTime",
          align: "center",
          customRender: v =>
            v ? this.moment(v).format("YYYY-MM-DD HH:mm") : "-"
        }
      ],
      loading: false,
      permissionValue: "menus.customerManagementMenu.clue"
    };
  },
  props: ["feedbackRecords", "archiveRecords", "reason_obj"],
  computed: {
    ...mapGetters(["user", "enumerate", "setting", "permission"])
  },
  mounted() {
    console.log(this.archiveRecords);
  },
  methods: {
    moment: moment
  }
};
</script>

<style scoped lang="less">
.title button {
  float: right;
  margin-right: 10px;
}
</style>
